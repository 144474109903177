//create an axios request in typescript with parameters
import {iApiRequest} from './type'
import {apiUrl} from "../helpers";
import axios from "axios";

export async function axiosSend(params: iApiRequest){
    const locale:string = localStorage.getItem('userLang')!
    try {
            return await axios({
            url: `${apiUrl}${params.url}`,
            method: params.method,
            data: params.data,
            headers: params.headers ? params.headers : {
                'Content-Type': params.multipartForm ? 'multipart/form-data' : 'application/json',
                'Accept-Language': locale
            },
            withCredentials: true
        }) as any
    }catch (e){
        if ((e as any).response.status === 401 && window.location.pathname !== '/') {
            localStorage.setItem('logoutReason','sessionInvalidated')
            window.location.href = '/'
            return
        }
        throw new Error(e as any)
    }
}